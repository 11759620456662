import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { useStaticQuery, Link, graphql } from "gatsby"
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography, IconButton } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Layout from "../components/Layout"
import useStyles from '../styles/experienceStyles'
import SEO from '../components/seo'


const ReactMarkdown = require('react-markdown')



const ExperienceItem = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "experiences"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            title
            seoDescription
            seoTitle
            seoKeywords
            references {
              img {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }, 
              title,
              subtitle
              id
              description
              seoDescription
              seoTitle
              seoKeywords
            }
          }
        }
      }
    }
  }
  `)
  const [id] = useQueryParam("id", StringParam);
  const experiences = query.allFile.nodes[0].childMarkdownRemark.frontmatter.references
  const experience = experiences.find((experience) => experience.id === id)
  const description = experience?.description
  const experienceImage = experience?.img.childImageSharp.fluid.originalImg
  const seoTitle = experience?.seoTitle
  const seoDescription = experience?.seoDescription
  const seoKeywords = experience?.seoKeywords
  const title = experience?.title
  const subtitle = experience?.subtitle
  return (
    <Layout>
      <SEO 
        title={seoTitle !== undefined ? seoTitle : title} 
        description ={seoDescription !== undefined ? seoDescription : subtitle} 
        keywords ={seoKeywords !== undefined ? seoKeywords : title}
      />
      <Link to='/experiences'>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <ArrowBackIosIcon />
        </IconButton>
      </Link>
      <Grid container justify="center" alignItems='center'>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardActionArea>
            <CardMedia>
              <img src={experienceImage} alt={experience?.subtitle} title={experience?.title} className={classes.cardMedia}/>
            </CardMedia>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" component="h2">
                  {experience?.title}
                </Typography>
                <Typography gutterBottom variant="h6" component="h3">
                  {experience?.subtitle}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>
        <div className={classes.content}>
          <ReactMarkdown source={description} />
        </div>
      </Grid>
    </Layout>
  )
}

export default ExperienceItem
